import React from 'react';
import Layout from 'components/layout';
import SectionContainer from '../components/sections/SectionContainer';
import { Heading } from 'grommet';

const LogIn = () => (
  <Layout pageTitle="Member Login">
    <SectionContainer height="90vh">
      <Heading>Comming Soon!</Heading>
    </SectionContainer>
  </Layout>
);

export default LogIn;
